<template lang="pug">
.team
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(name="seasonInquiry", v-model="seasonInquiryType")
            option(:value="index", v-for="(item, index) in leagueData.seasons") {{ item.name }}
  section
    v-container
      v-row.mt-5
        v-col.text-center(cols="12")
          h5.normal_title_h5 球隊列表
      v-row.table_row
        v-col#sticky-nav.table_col.table.position-relative(cols="12")
          table
            thead
              tr.bottom_head
                sticky-column.text-center(
                  style="width: 50px; min-width: 40px",
                  :title="headTitleList[0].title",
                  :offset="0"
                )
                sticky-column.bdr2.text-center(
                  style="width: 100px; min-width: 100px",
                  :title="headTitleList[1].title",
                  :offset="40"
                )
                th(
                  v-if="index > 1",
                  v-for="(title, index) in headTitleList",
                  :key="index",
                  :class="{ bdr: title.divider }"
                ) {{ title.title }}
            tbody
              tr(v-for="(team, index) in teamRankingList", :key="index")
                sticky-column.bdl.text-center(
                  style="width: 40px; min-width: 40px",
                  :title="'1st'",
                  :offset="0"
                )
                  .ranking.text-center(
                    :class="[index < 3 ? 'text_secondary' : 'lightGray']"
                  ) {{ getRankText(index + 1) }}

                sticky-column.bdr2.text-center(
                  style="width: 100px; min-width: 100px",
                  :offset="40"
                )
                  .team_container.clickable.d-flex.align-center(
                    @click="teamClick(team)"
                  )
                    img.temp_logo_hidden(
                      width="30px",
                      :src="team.info.photo.image"
                    )
                    span {{ team.info.name }}

                td.bdr {{ team.info.record_matches }}
                td {{ team.info.wins }}
                td {{ team.info.losses }}
                td.bdr {{ (team.info.win_rate * 100).toFixed(1) }}%

                td.bdr 
                  .game_container.clickable.d-flex.align-center(
                    style="cursor: pointer",
                    @click="recordClick(team)"
                  )
                    font-awesome-icon(:icon="['fas', 'calendar-alt']") 
                    span.ml-1 戰績

                td 
                  .game_container.clickable.d-flex.align-center(
                    style="cursor: pointer",
                    @click="dataClick(team)"
                  )
                    font-awesome-icon(:icon="['fas', 'chart-bar']") 
                    span.ml-1 數據排行
  section.bar_container
    v-container
      v-row(style="margin-top: 100px")
        v-col.title(cols="12")
          h5.normal_title_h5 進攻排名
          span Offensive
      v-row.my-2
        v-col.position-relative(style="padding-bottom: 0", cols="12")
          v-divider.position-absolute.bottom-0.w-100.left-0
          .tabs
            v-tabs.sheet-s-border.position-relative(
              v-model="offensiveTab",
              ripple="false"
            )
              v-tabs-slider(hidden="true")
              v-tab(
                v-for="(ranking, index) in offensiveTitleList",
                :key="ranking.type",
                :ripple="false"
              ) {{ ranking.title }}
          span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Offensive
      v-row.py-1.px-1.px-md-10(
        v-for="(item, index) in currentOffensiveList",
        align="center",
        :key="index"
      )
        v-col.d-flex.justify-end.align-center(cols="auto", md="2")
          .ranking.text-center.ranking_mark(
            :class="[item.ranking.ranking <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(item.ranking.ranking) }}
          .text-body-2.font-weight-bold.clickable.d-inline-block.ml-4(
            @click="teamClick(item)"
          ) {{ item.info.name }}
        v-col.d-flex.align-center.flex-grow-1(cols="auto", md="10")
          percentage-bar(
            :height="14",
            :value="item.ranking.value",
            :deno="item.deno",
            :percent="item.percent"
          )

  section.bar_container
    v-container
      v-row(style="margin-top: 100px")
        v-col.title(cols="12")
          h5.normal_title_h5 防守排名
          span Defensive
      v-row.my-2
        v-col.position-relative(style="padding-bottom: 0", cols="12")
          v-divider.position-absolute.bottom-0.w-100.left-0
          .tabs
            v-tabs.sheet-s-border.position-relative(
              v-model="defensiveTab",
              ripple="false"
            )
              v-tabs-slider(hidden="true")
              v-tab(
                v-for="(ranking, index) in defensiveTitleList",
                :key="ranking.type",
                :ripple="false"
              ) {{ ranking.title }}
          span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Defensive

      v-row.py-1.px-1.px-md-10(
        v-for="(item, index) in currentDefensiveList",
        align="center",
        :key="index"
      )
        v-col.d-flex.justify-end.align-center(cols="auto", md="2")
          .ranking.text-center.ranking_mark(
            :class="[item.ranking.ranking <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(item.ranking.ranking) }}
          .text-body-2.font-weight-bold.clickable.d-inline-block.ml-4(
            @click="teamClick(item)"
          ) {{ item.info.name }}
        v-col.d-flex.align-center.flex-grow-1(cols="auto", md="10")
          percentage-bar(
            :height="14",
            :value="item.ranking.value",
            :deno="item.deno",
            :percent="item.percent"
          )

  section.bar_container
    v-container
      v-row(style="margin-top: 100px")
        v-col.title(cols="12")
          h5.normal_title_h5 投籃排名
          span Field Goal
      v-row.my-2
        v-col.position-relative(style="padding-bottom: 0", cols="12")
          v-divider.position-absolute.bottom-0.w-100.left-0
          .tabs
            v-tabs.sheet-s-border.position-relative(
              v-model="fieldGoalTab",
              ripple="false"
            )
              v-tabs-slider(hidden="true")
              v-tab(
                v-for="(ranking, index) in fieldGoalTitleList",
                :key="ranking.type",
                :ripple="false"
              ) {{ ranking.title }}
          span.d-none.d-sm-block.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Field Goal
      v-row.py-1.px-1.px-md-10(
        v-for="(item, index) in currentFieldGoalList",
        align="center",
        :key="index"
      )
        v-col.d-flex.justify-end.align-center(cols="auto", md="2")
          .ranking.text-center.ranking_mark(
            :class="[item.ranking.ranking <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(item.ranking.ranking) }}
          .text-body-2.font-weight-bold.clickable.d-inline-block.ml-4(
            @click="teamClick(item)"
          ) {{ item.info.name }}
        v-col.d-flex.align-center.flex-grow-1(cols="auto", md="10")
          percentage-bar(
            :height="14",
            :value="item.ranking.value",
            :deno="item.deno",
            :percent="item.percent"
          )

  section.bar_container
    v-container
      v-row(style="margin-top: 100px")
        v-col.title(cols="12")
          h5.normal_title_h5 罰球排名
          span Free Throw
      v-row.my-2
        v-col.position-relative(style="padding-bottom: 0", cols="12")
          v-divider.position-absolute.bottom-0.w-100.left-0
          .tabs
            v-tabs.sheet-s-border.position-relative(
              v-model="freeThrowTab",
              ripple="false"
            )
              v-tabs-slider(hidden="true")
              v-tab(
                v-for="(ranking, index) in freeThrowTitleList",
                :key="ranking.type",
                :ripple="false"
              ) {{ ranking.title }}
          span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Free Throw
      v-row.py-1.px-1.px-md-10(
        v-for="(item, index) in currentFreeThrowList",
        align="center",
        :key="index",
        style="padding: 5px 40px"
      )
        v-col.d-flex.justify-end.align-center(cols="auto", md="2")
          .ranking.text-center.ranking_mark(
            :class="[item.ranking.ranking <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(item.ranking.ranking) }}
          .text-body-2.font-weight-bold.clickable.d-inline-block.ml-4(
            @click="teamClick(item)"
          ) {{ item.info.name }}
        v-col.d-flex.align-center.flex-grow-1(cols="auto", md="10")
          percentage-bar(
            :height="14",
            :value="item.ranking.value",
            :deno="item.deno",
            :percent="item.percent"
          )
</template>

<script>
import RankingMark from "@/components/common/RankingMark";
import PercentageBar from "@/components/common/PercentageBar";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getLeagueList } from "@/api/league";
import { getTeamRankingData, getTeamSeasonData } from "@/api/team";

export default {
  name: "LeagueTeamOverview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    RankingMark,
    PercentageBar,
    StickyColumn,
  },
  data() {
    return {
      league: this.$route.params.league,
      headTitleList: [
        { title: "排名", divider: false },
        { title: "球隊", divider: true },
        { title: "場次", divider: true },
        { title: "勝", divider: false },
        { title: "敗", divider: false },
        { title: "勝率", divider: true },
        { title: "賽程", divider: true },
        { title: "數據", divider: false },
      ],
      leagueData: {
        info: {},
      },
      seasonInquiryType: 0,
      teamRankingList: [],
      teamRankingData: {},

      offensiveTab: 0,
      defensiveTab: 0,
      fieldGoalTab: 0,
      freeThrowTab: 0,
      currentOffensiveList: [],
      currentDefensiveList: [],
      currentFieldGoalList: [],
      currentFreeThrowList: [],

      offensiveTitleList: [
        {
          type: "score",
          title: "得分",
        },
        {
          type: "assist",
          title: "助攻",
        },
        {
          type: "turnover",
          title: "失誤",
        },
      ],
      defensiveTitleList: [
        {
          type: "rebound",
          title: "籃板",
        },
        {
          type: "block",
          title: "阻攻",
        },
        {
          type: "steal",
          title: "抄截",
        },
      ],
      fieldGoalTitleList: [
        {
          type: "fieldGoalPer",
          title: "投籃命中率",
        },
        {
          type: "threePointShotPer",
          title: "三分命中率",
        },
        {
          type: "threePointShot",
          title: "三分得分",
        },
      ],
      freeThrowTitleList: [
        {
          type: "freeThrowPer",
          title: "罰球命中率",
        },
        {
          type: "freeThrow",
          title: "罰球得分",
        },
      ],

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    seasonInquiryType() {
      this.teamRankingList = [];
      this.teamRankingData = {};
      this.offensiveTab = 0;
      this.defensiveTab = 0;
      this.fieldGoalTab = 0;
      this.freeThrowTab = 0;
      this.currentOffensiveList = [];
      this.currentDefensiveList = [];
      this.currentFieldGoalList = [];
      this.currentFreeThrowList = [];

      this.initData();
    },
    offensiveTab(value) {
      this.setOffensiveList(value);
    },
    defensiveTab(value) {
      this.setDefensiveList(value);
    },
    fieldGoalTab(value) {
      this.setFieldGoalList(value);
    },
    freeThrowTab(value) {
      this.setFreeThrowList(value);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getLeagueList();
      await this.getTeamRankingData();
      await this.getTeamRankingList();
    },
    async getLeagueList() {
      await getLeagueList().then((response) => {
        for (let index in response.data) {
          let temp = response.data[index];
          if (temp.name === this.league) {
            this.leagueData = Object.assign({}, temp);
            break;
          }
        }
      });
    },
    async getTeamRankingList() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
      };
      await getTeamSeasonData(temp).then((response) => {
        this.teamRankingList = response.data.sort(function (a, b) {
          return a.ranking.win_rate.ranking - b.ranking.win_rate.ranking;
        });
      });
    },
    async getTeamRankingData() {
      let temp = {
        season_id: this.leagueData.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        ranking: 5,
      };
      await getTeamRankingData(temp).then((response) => {
        this.teamRankingData = response.data;
        this.setOffensiveList(0);
        this.setDefensiveList(0);
        this.setFieldGoalList(0);
        this.setFreeThrowList(0);
      });
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    recordClick(team) {
      this.$router.push({
        name: "LeagueTeamRecords",
        params: {
          id: team.info.id,
        },
      });
    },
    dataClick(team) {
      this.$router.push({
        name: "LeagueTeamStatistics",
        params: {
          id: team.info.id,
        },
      });
    },
    teamClick(team) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          league: this.leagueData.id,
          season: this.leagueData.seasons[this.seasonInquiryType].id,
          id: team.info.id,
        },
      });
    },
    setOffensiveList(tabIndex) {
      switch (tabIndex) {
        case 0:
          for (let index in this.teamRankingData.avg_pts) {
            let temp = this.teamRankingData.avg_pts[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_pts[0].ranking.value;
          }
          this.currentOffensiveList = this.teamRankingData.avg_pts;
          break;
        case 1:
          for (let index in this.teamRankingData.avg_ast) {
            let temp = this.teamRankingData.avg_ast[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_ast[0].ranking.value;
          }
          this.currentOffensiveList = this.teamRankingData.avg_ast;
          break;
        case 2:
          for (let index in this.teamRankingData.avg_tov) {
            let temp = this.teamRankingData.avg_tov[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_tov[0].ranking.value;
          }
          this.currentOffensiveList = this.teamRankingData.avg_tov;
          break;
      }
    },
    setDefensiveList(tabIndex) {
      switch (tabIndex) {
        case 0:
          for (let index in this.teamRankingData.avg_reb) {
            let temp = this.teamRankingData.avg_reb[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_reb[0].ranking.value;
          }
          this.currentDefensiveList = this.teamRankingData.avg_reb;
          break;
        case 1:
          for (let index in this.teamRankingData.avg_blk) {
            let temp = this.teamRankingData.avg_blk[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_blk[0].ranking.value;
          }
          this.currentDefensiveList = this.teamRankingData.avg_blk;
          break;
        case 2:
          for (let index in this.teamRankingData.avg_stl) {
            let temp = this.teamRankingData.avg_stl[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_stl[0].ranking.value;
          }
          this.currentDefensiveList = this.teamRankingData.avg_stl;
          break;
      }
    },
    setFieldGoalList(tabIndex) {
      switch (tabIndex) {
        case 0:
          for (let index in this.teamRankingData.avg_fg_percent) {
            let temp = this.teamRankingData.avg_fg_percent[index];
            temp.percent = true;
            temp.deno = this.teamRankingData.avg_fg_percent[0].ranking.value;
          }
          this.currentFieldGoalList = this.teamRankingData.avg_fg_percent;
          break;
        case 1:
          for (let index in this.teamRankingData.avg_three_pt_fg_percent) {
            let temp = this.teamRankingData.avg_three_pt_fg_percent[index];
            temp.percent = true;
            temp.deno =
              this.teamRankingData.avg_three_pt_fg_percent[0].ranking.value;
          }
          this.currentFieldGoalList =
            this.teamRankingData.avg_three_pt_fg_percent;
          break;
        case 2:
          for (let index in this.teamRankingData.avg_three_pts) {
            let temp = this.teamRankingData.avg_three_pts[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_three_pts[0].ranking.value;
          }
          this.currentFieldGoalList = this.teamRankingData.avg_three_pts;
          break;
      }
    },
    setFreeThrowList(tabIndex) {
      switch (tabIndex) {
        case 0:
          for (let index in this.teamRankingData.avg_ft_percent) {
            let temp = this.teamRankingData.avg_ft_percent[index];
            temp.percent = true;
            temp.deno = this.teamRankingData.avg_ft_percent[0].ranking.value;
          }
          this.currentFreeThrowList = this.teamRankingData.avg_ft_percent;
          break;
        case 1:
          for (let index in this.teamRankingData.avg_ft_pts) {
            let temp = this.teamRankingData.avg_ft_pts[index];
            temp.ranking.value = Number(temp.ranking.value.toFixed(1));
            temp.deno = this.teamRankingData.avg_ft_pts[0].ranking.value;
          }
          this.currentFreeThrowList = this.teamRankingData.avg_ft_pts;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
.bar_container {
  .title {
    text-align: center;
    h5 {
      font-weight: bold;
      display: block;
    }

    span {
      display: none;
    }
  }
}

svg {
  color: $secondary;
}

::v-deep.theme--light.v-tabs > .v-tabs-bar {
  background: transparent;
}

span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute {
  top: 12px;
  right: 12px;
  font-family: Helvetica;
  font-size: 20px;
}

.ranking {
  font-weight: bold;
}

@media (max-width: 599px) {
  .bar_container {
    .title {
      text-align: left;
      h5 {
        display: inline-block;
        line-height: 30px;
      }
      span {
        font-weight: bold;
        font-style: italic;
        display: inline-block;
        margin-left: 5px;
        line-height: 30px;
      }
    }
  }
}
</style>
