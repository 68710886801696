<template lang="pug">
.progress_bar.d-flex.align-center.w-100
  v-progress-linear(
    :height="height",
    :style="{ borderRadius: height / 2 }",
    background-color="#ECECEC",
    color="#487AA4",
    :value="getLinePercentage"
  )
  .text_value.ml-5 {{ percent ? showPercentText : value }}
</template>

<script>
export default {
  name: "PercentageBar",
  props: {
    value: Number,
    deno: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 10,
    },
    percent: Boolean,
  },
  computed: {
    getLinePercentage() {
      return (this.value / this.deno) * 100;
    },
    showPercentText() {
      return (this.value * 100).toFixed(1) + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-progress-linear {
  border-radius: 7px;
  background: #ececec;
}

::v-deep .v-progress-linear__determinate {
  border-radius: 7px;
}

.text_value {
  font-family: Helvetica;
  font-size: 20px !important;
  font-weight: bold;
  font-style: italic;
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: right;
}

@media (max-width: 599px) {
  ::v-deep .v-progress-linear__determinate,
  ::v-deep .v-progress-linear {
    height: 10px !important;
    border-radius: 5px !important;
  }

  .text_value {
    font-size: 18px !important;
    width: 40px;
  }
}
</style>
