<template lang="pug">
v-sheet.text-center.d-inline-block(
  width="20",
  height="20",
  color="#E2252F",
  dark="",
  tile
) {{ ranking }}
</template>

<script>
export default {
  name: "RankingMark",
  props: {
    ranking: Number,
  },
};
</script>
